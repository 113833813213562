import { SvgIconProps, Tooltip } from "@material-ui/core"

import { formatLastHeardFrom } from "src/data/devices/logic/deviceLogic"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { THome } from "src/data/homes/types/homeTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import StopwatchIcon from "src/ui/icons/stopwatch.svg"

export function DeviceLastHeardFromAtIcon({
  time,
  timezone,
  ...props
}: {
  time: TDevice["last_heard_from_at"]
  timezone: THome["timezone"]
} & SvgIconProps) {
  const { t, langKeys } = useTranslate()
  const clockType = useGetUser().clock_type

  const formattedLastHeardFrom = formatLastHeardFrom(
    time,
    t,
    clockType,
    timezone
  )

  return (
    <Tooltip title={`${t(langKeys.last_updated)} ${formattedLastHeardFrom}`}>
      <div>
        <StopwatchIcon width={15} height={15} {...props} />
      </div>
    </Tooltip>
  )
}
